<template>
  <v-container fluid class="dcl">
    <v-layout wrap>
    <v-col cols="4" sm="4" md="4">
      <label class="label-modal">Distrito</label>
      <v-autocomplete :items="distritos" :color="$primary" v-model="MyObject.id_distrito" item-text="nome" item-value="id" @change="changeDistrito(false)" no-data-text="Sem resultados"></v-autocomplete>
    </v-col>
    <v-col cols="4" sm="4" md="4">
      <label class="label-modal">Concelho</label>
      <v-autocomplete :items="concelhos" :color="$primary" v-model="MyObject.id_concelho" item-text="nome" item-value="id" @change="changeConcelho(false)" no-data-text="Sem resultados"></v-autocomplete>
    </v-col>
    <v-col cols="4" sm="4" md="4">
      <label class="label-modal">Localidade</label>
      <v-autocomplete :items="localidades" :color="$primary" v-model="MyObject.id_localidade" item-text="nome" item-value="id" no-data-text="Sem resultados"></v-autocomplete>
    </v-col>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  props: {
    MyObject: Object,
  },
  data: () => ({
    concelhos: [],
    localidades: []
  }),
  computed: {
    distritos() {
      return this.$store.getters['localidade/getDistritos'];
    }
  },
  created() {
    this.init();
  },
  watch:{
    MyObject(val){
      if(!val.id_distrito) {
        this.concelhos = [];
        this.localidades = [];
      } else if(!val.id_concelho) {
        this.localidades = [];
      }
    }
  },
  methods: {
    init() {
      if(this.MyObject.id_distrito!=null) this.changeDistrito(true);

      setTimeout(() => {
        this.$forceUpdate();
      }, 110);
    },

    changeDistrito(param = false) {
      this.concelhos = [];
      this.localidades = [];
      if(!param) this.MyObject.id_concelho = -1;

      this.$store.dispatch("localidade/getConcelhos", {
        id_distrito: this.MyObject.id_distrito
      }).then((res) => {
        this.concelhos = res ? res : [];

        if(param || (this.concelhos.length === 1 && this.MyObject.id_concelho === -1)) this.changeConcelho(param);
      });
    },

    changeConcelho(param = false) {
      this.localidades = [];
      if(!param) this.MyObject.id_localidade = -1;

      this.$store.dispatch("localidade/getLocalidades", {
        id_concelho: this.MyObject.id_concelho
      }).then((res) => {
        this.localidades = res ? res : [];
      });
    },
  },
}
</script>
<style scoped>
.dcl {
  padding: 0;
  margin: 0;
}
.dcl .v-select,
.dcl .col-4,
.dcl .v-input {
  padding: 0 10px 0 0 !important;
}
</style>
